// Helpers
// -----------------------------------------------------------------------------

.h-100 {
  height: 100% !important;
}

.w100 {
  width: 100% !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(248, 249, 250, var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: #000 !important;
}

.visually_hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    h2 {
      min-height: 110px;
    }
  }
}

.box-centered {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

div:target {
  scroll-margin-top: 120px;
}

.mw-250 {
  max-width: 250px;
}
