.ui-datepicker {
  background-color: #f6f6f6;
  padding: 30px;
  .ui-datepicker-header {
    display: flex;
    flex-wrap: wrap;
    a.ui-datepicker-prev,
    a.ui-datepicker-next {
      flex: 0 0 auto;
      width: 46%;
      margin: 0 2%;
      background-color: #96bc0e;
      color: #000;
      span {
        display: block;
        padding: 20px 20px;
        text-align: center;
      }
    }
    .ui-datepicker-title {
      padding: 20px;
      text-align: center;
      width: 100%;
      display: block;
      font-weight: 700;
    }
  }
  table.ui-datepicker-calendar {
    margin-top: 0;
    tr th,
    tr td {
      text-align: center;
    }
  }
}