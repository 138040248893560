/**
 * Containers
 */
.container-lg,
.container-md,
.container-sm,
.container,
.container-fluid {
   width: 100%;
   margin: 0 auto;
}

@media (max-width: 1024px) {
  header>nav>.container {
    margin: 0 -10px;
  }
}

@media (min-width: $screen-sm) {
  .container-md,
  .container-sm,
  .container {
     max-width: $screen-sm;
   }
}

@media (min-width: $screen-md) {
  .container-md,
  .container-sm,
  .container {
     max-width: $screen-md;
  }
}

@media (min-width: $screen-lg) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: $screen-lg;
   }
}