// Styles for the primary-tabs and actions.
// -----------------------------------------------------------------------------

.admin-actions-bar {
  background-color: #252b37;
  .tabs {
    margin: 0;
    .nav-tabs,
    .nav-pills {
      margin: 0;
      border-bottom: none;
    }
  }
  .action-links.nav.nav-tabs {
    margin: 0;
    border-bottom: none;
    .btn-sm {
      font-size: $font-size-base;
    }
  }
}