#serviss-order-orders-overview {
  table tr th,
  table tr td {
    vertical-align: middle;
  }
  table tr.validate td {
    background-color: #dfeae9 !important;
  }
}


@media (max-width: $screen-lg) {
  .page-admin-config-system-orders {
    #main {
      #content {
        padding: 0;
        margin: 1em auto;
      }
    }
  }
  .page-admin.page-admin-recherche-utilisateurs {
    #main{
      #content {
        padding: 0;
        margin: 1em auto;
      }
    }
  }
}