.admin-menu .navbar-fixed-top {
  top: 28px;
  border-width: 0 0 1px;
  z-index: 950;
}

.main {
  padding-top: 132px;
}

.main.admin-actions {
  padding-top: 128px;
}

.admin-menu .main {
  padding-top: 132px;
}

.admin-menu .main.admin-actions {
  padding-top: 175px;
}

@media (max-width: 1024px) {
  .navbar-fixed-top,
  .admin-menu .navbar-fixed-top {
    top: 0;
    position: relative;
  }

  .main {
    padding-top: 0;
  }

  .main.admin-actions {
    padding-top: 0;
  }

  .admin-menu .main {
    padding-top: 0;
  }

  .admin-menu .main.admin-actions {
    padding-top: 0;
  }
}