// @file
// SASS partials import
// -----------------------------------------------------------------------------

// Bootstrap global partials.
// -----------------------------------------------------------------------------
@import "base/variables";
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Bootswatch partial
// -----------------------------------------------------------------------------
@import "bootswatch/variables";

// Serviss 2023 global partials.
// -----------------------------------------------------------------------------
@import "radix/mixins";
@import "base/mixins";
@import "base/helpers";

// Bootstrap reset and dependencies
// -----------------------------------------------------------------------------
@import "bootstrap/normalize";
@import "bootstrap/print";
@import "bootstrap/glyphicons";

// Bootstrap core
// -----------------------------------------------------------------------------
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";

// Bootstrap components
// -----------------------------------------------------------------------------
@import "bootstrap/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";
@import "bootstrap/navs";
@import "bootstrap/navbar";
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
@import "bootstrap/pager";
@import "bootstrap/labels";
@import "bootstrap/badges";
@import "bootstrap/jumbotron";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "bootstrap/progress-bars";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/panels";
@import "bootstrap/responsive-embed";
@import "bootstrap/wells";
@import "bootstrap/close";

// Bootstrap components w/ JavaScript
// -----------------------------------------------------------------------------
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
@import "bootstrap/carousel";

// Bootstrap utility classes
// -----------------------------------------------------------------------------
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";

// FontAwesome
// -----------------------------------------------------------------------------
@import "font-awesome";

// Bootswatch
// -----------------------------------------------------------------------------
@import "bootswatch/bootswatch";

// Radix styles overrides and fixes.
// -----------------------------------------------------------------------------
// Radix styles overrides and fixes.
// -----------------------------------------------------------------------------
@import "radix/admin";
@import "radix/comment";
@import "radix/form";
@import "radix/layout";
@import "radix/maintenance";
@import "radix/modal";
@import "radix/nav";
@import "radix/node";
@import "radix/panel";
@import "radix/structure";
@import "radix/view";

// Serviss 2023 partials
// --------------------------------------------------
@import "base/bootstrap5-gutters";
@import "base/bootstrap5-grid";
@import "base/bootstrap5-display-options";
@import "base/bootstrap5-flex";
@import "base/bootstrap5-containers";
@import "base/bootstrap5-margins";
@import "base/bootstrap5-padding";
@import "base/bootstrap5-text";
@import "base/typography";
@import "layout/_fixed_navbar.scss";
@import "layout/_page.scss";
@import "layout/_system_admin_block.scss";
@import "components/_admin-actions.scss";
@import "components/_block.scss";
@import "components/_ckeditor.scss";
@import "components/_comment.scss";
@import "components/_field.scss";
@import "components/_form.scss";
@import "components/_language-selector.scss";
@import "components/_messages.scss";
@import "components/_node_paragraphs-form.scss";
@import "components/_node.scss";
@import "components/_panel.scss";
@import "components/_serviss-orders-table.scss";
@import "components/_site-nav.scss";
@import "components/_structure.scss";
@import "components/_term.scss";
@import "components/_ui-datepicker.scss";
@import "components/_user.scss";
@import "components/_view.scss";


/**
 * Site Navigation
 */

 .site-nav {
  font-family: "Squada One", cursive;
  text-transform: uppercase;
}

.site-nav button {
  text-transform: uppercase;
}

.site-nav ul.menu-nav {
  margin-top: 0;
}

.menu-nav li {
  width: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-nav ul.menu-nav li {
  margin-top: 0;
  line-height: 1rem;
}

.btn-green {
  background-color: #96be0e;
  border: 2px solid #96be0e;
  color: white;
}

.btn-outline-green {
  border: 2px solid #96be0e;
}

* {
  border-radius: 0 !important;
}

.navbar-brand {
  background: no-repeat;
  background-size: contain;
  text-indent: -9999px;
  margin-top: -25px;
}

@media (max-width: 360px) {
  .navbar-brand {
    margin-bottom: 20px !important;
  }
}

#footer {
  margin: 0 auto;
}

.footer-site-logo {
  background-size: contain;
  text-indent: -9999px;
  margin-top: -20px;
  display: block;
}

.dropdown-toggle:after {
  vertical-align: 0.155em;
}

.dropdown-menu.language-switcher {
  min-width: auto;
  padding: 0 7px;
}

/**
 * Explanation Paragraph Type
 */

.step-content-block {
  max-width: 777px;
  box-shadow: rgba(0 0 0 / 15%) 0 2px 8px;
}

.step-title {
  color: #fff;
  background-color: #96be0e;
  text-align: center;
}

.step-title h3 {
  display: inline-block;
  font-size: 1.3rem;
  max-width: 100px;
  line-height: 1.3rem;
  margin-bottom: 0;
  vertical-align: middle;
  text-align: left;
}

.step-title .step-number {
  font-family: "Be Vietnam Pro", sans-serif;
  display: inline-block;
  width: auto;
  font-size: 3rem;
  margin-right: 5px;
  vertical-align: middle;
  line-height: 72px;
}

.step-button {
  display: flex;
  flex: 1 0 0%;
  background-color: #db0961;
  font-family: "Squada One", cursive;
  text-transform: uppercase;
  font-size: 1.3rem;
  text-decoration: none;
  color: white;
  vertical-align: middle;
  justify-content: center;
  padding: 0;
  min-height: 72px;
}

.step-button span {
  margin: auto 0;
  width: 100%;
  text-align: center;
}

a.step-button:hover {
  color: #96be0e;
  background-color: #7f0036;
  transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
  transition-duration: 0.3s;
  text-decoration: none;
}

.step-button.border-right {
  border-right: rgba(255 255 255 / 50%) 1px dotted;
}

@media (max-width: calc($screen-md - 1px)) {
  .step {
    background-size: contain !important;
    background-repeat: no-repeat;
    background-color: #96be0e;
  }

  .step-content-block {
    max-width: 100%;
    .row {
      margin-top: 0;
    }
    .row>* {
      margin-top: 0 !important;
    }
  }

  .step-title {
    text-align: left;
    padding: 0 70px;
    padding-top: 40px;
  }

  .step-image {
    position: relative;
    top: 35px !important;
    width: 200px;
  }

  .container,
  .row {
    --bs-gutter-x: 0 !important;
  }

  .box .explanation-box {
    padding: 50px 100px !important;
    max-width: 100%;
  }

  .step-align-left {
    padding-left: 35px !important;
    text-align: left;
  }

  .step-title.step-align-left {
    padding-left: 70px !important;
  }

  .step-align-right {
    padding-right: 35px !important;
    text-align: right;
  }

  .step-title.step-align-right {
    padding-right: 70px !important;
  }
}

@media (max-width: 290px) {
  .step-align-left {
    padding-left: 0 !important;
    text-align: center;
  }

  .step-align-right {
    padding-right: 0 !important;
    text-align: center;
  }

  .step-image {
    display: block;
    margin: 0 auto;
    left: auto;
  }

  .step-button {
    min-height: 50px;
  }

  .step-title {
    text-align: center;
    padding: 40px 20px 0;
  }
}

@media (max-width: 400px) {
  .step-button {
    font-size: 1rem;
  }

  .box .explanation-box {
    padding: 50px 10% !important;
    text-align: center;
  }
}

@media (max-width: 215px) {
  .step-image {
    width: 100%;
  }
}

#livraison {
  background-position: 50% 90% !important;
}

@media (max-width: calc($screen-md - 1px)) {
  #livraison {
    background-position: 0% -3% !important;
    .bg-light {
      background-color: #eef4e5 !important;
    }
  }
}

@media (min-width: 768px) {
  #livraison .container-md {
    padding-bottom: 7rem !important;
  }
}

#serviss-puppy {
  background-image: url("../images/closing-puppy.png"), url("../images/closing-clothes-line.png"), url("../images/sky.svg");
  background-size: 125px 203px, contain, cover;
  background-position: 70% 35%, 50% 30%, 80% 50%;
  background-repeat: no-repeat, no-repeat;
  height: 70vh;
}

#introduction p.lead {
  max-width: 250px;
  text-align: center;
  margin: 0 auto;
}

@media (min-width: $screen-xs) {
  #introduction p.lead {
    max-width: 250px;
    text-align: left;
    margin: 0;
  }
}

body.node-type-serviss-page {
  background-color: #00c0ff;
}

body.node-type-serviss-page.page-node-edit,
body.node-type-serviss-page.page-node-translate,
body.node-type-serviss-page.page-node-devel {
  background-color: inherit;
}
