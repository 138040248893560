/**
 * Flex behaviours
 */

// Flex direction
.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

// Justify content
.justify-content-start {
  justify-content: start !important;
}

.justify-content-end {
  justify-content: end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

@media (min-width: $screen-sm) {
  //Direction
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  // Justify content
  .justify-content-sm-start {
    justify-content: start !important;
  }

  .justify-content-sm-end {
    justify-content: end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
}

@media (min-width: $screen-md) {
  // Direction
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  // Justify content
  .justify-content-md-start {
    justify-content: start !important;
  }

  .justify-content-md-end {
    justify-content: end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
}

@media (min-width: $screen-lg) {
  // Direction
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  // Justify content
  .justify-content-lg-start {
    justify-content: start !important;
  }

  .justify-content-lg-end {
    justify-content: end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
}