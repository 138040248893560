form.node-form {

  div.field-type-paragraphs {

    div.table-responsive {

      table {
        display: block;
        width: 100%;

        thead {
          display: block;
          width: 100%;

          tr {
            display: flex;
            th.field-label {
              width: 100%;
            }
          }
        }

        tbody {
          display: block;
          width: 100%;

          tr {
            display: flex;
            width: 100%;
            padding: 0;

            td {
              display: block;
              width: calc(100% - 30px);

              .filter-guidelines ul.tips li {
                white-space: normal;
              }
            }
            td.field-multiple-drag {
              width: 30px;
            }
          }
        }
      }
    }
  }
}