/**
 * Gutters - All screens
 */

// All
.g-0 {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.g-1 {
  --bs-gutter-x: $spacer * .25;
  --bs-gutter-y: $spacer * .25;
}

.g-2 {
  --bs-gutter-x: $spacer * .5;
  --bs-gutter-y: $spacer * .5;
}

.g-3 {
  --bs-gutter-x: $spacer;
  --bs-gutter-y: $spacer;
}

.g-4 {
  --bs-gutter-x: $spacer * 1.5;
  --bs-gutter-y: $spacer * 1.5;
}

.g-5 {
  --bs-gutter-x: $spacer * 3;
  --bs-gutter-y: $spacer * 3;
}

.g-auto {
  --bs-gutter-x: auto;
  --bs-gutter-y: auto;
}

// Top and Bottom (Y)
.gy-0 {
  --bs-gutter-y: 0;
}

.gy-1 {
  --bs-gutter-y: $spacer * .25;
}

.gy-2 {
  --bs-gutter-y: $spacer * .5;
}

.gy-3 {
  --bs-gutter-y: $spacer;
}

.gy-4 {
  --bs-gutter-y: $spacer * 1.5;
}

.gy-5 {
  padding-top: $spacer * 3;
}

.gy-auto {
  --bs-gutter-y: auto;
}

// Left and Right (X)
.gx-0 {
  --bs-gutter-x: 0;
}

.gx-1 {
  --bs-gutter-x: $spacer * .25;
}

.gx-2 {
  --bs-gutter-x: $spacer * .5;
}

.gx-3 {
  --bs-gutter-x: $spacer;
}

.gx-4 {
  --bs-gutter-x: $spacer * 1.5;
}

.gx-5 {
  --bs-gutter-x: $spacer * 3;
}

.gx-auto {
  --bs-gutter-x: auto;
}

/**
 * Padding - SM
 */

 @media (min-width: $screen-sm) {
  // All
  .g-sm-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .g-sm-1 {
    --bs-gutter-x: $spacer * .25;
    --bs-gutter-y: $spacer * .25;
  }

  .g-sm-2 {
    --bs-gutter-x: $spacer * .5;
    --bs-gutter-y: $spacer * .5;
  }

  .g-sm-3 {
    --bs-gutter-x: $spacer;
    --bs-gutter-y: $spacer;
  }

  .g-sm-4 {
    --bs-gutter-x: $spacer * 1.5;
    --bs-gutter-y: $spacer * 1.5;
  }

  .g-sm-5 {
    --bs-gutter-x: $spacer * 3;
    --bs-gutter-y: $spacer * 3;
  }

  .g-sm-auto {
    --bs-gutter-x: auto;
    --bs-gutter-y: auto;
  }

  // Top and Bottom (Y)
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .gy-sm-1 {
    --bs-gutter-y: $spacer * .25;
  }

  .gy-sm-2 {
    --bs-gutter-y: $spacer * .5;
  }

  .gy-sm-3 {
    --bs-gutter-y: $spacer;
  }

  .gy-sm-4 {
    --bs-gutter-y: $spacer * 1.5;
  }

  .gy-sm-5 {
    --bs-gutter-y: $spacer * 3;
  }

  .gy-sm-auto {
    --bs-gutter-y: auto;
  }

  // Left and Right (X)
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .gx-sm-1 {
    --bs-gutter-x: $spacer * .25;
  }

  .gx-sm-2 {
    --bs-gutter-x: $spacer * .5;
  }

  .gx-sm-3 {
    --bs-gutter-x: $spacer;
  }

  .gx-sm-4 {
    --bs-gutter-x: $spacer * 1.5;
  }

  .gx-sm-5 {
    --bs-gutter-x: $spacer * 3;
  }

  .gx-sm-auto {
    --bs-gutter-x: auto;
  }

}





/**
 * Padding - MD
 */

@media (min-width: $screen-md) {
  // All
  .g-md-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .g-md-1 {
    --bs-gutter-x: $spacer * .25;
    --bs-gutter-y: $spacer * .25;
  }

  .g-md-2 {
    --bs-gutter-x: $spacer * .5;
    --bs-gutter-y: $spacer * .5;
  }

  .g-md-3 {
    --bs-gutter-x: $spacer;
    --bs-gutter-y: $spacer;
  }

  .g-md-4 {
    --bs-gutter-x: $spacer * 1.5;
    --bs-gutter-y: $spacer * 1.5;
  }

  .g-md-5 {
    --bs-gutter-x: $spacer * 3;
    --bs-gutter-y: $spacer * 3;
  }

  .g-md-auto {
    --bs-gutter-x: auto;
    --bs-gutter-y: auto;
  }

  // Top and Bottom (Y)
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .gy-md-1 {
    --bs-gutter-y: $spacer * .25;
  }

  .gy-md-2 {
    --bs-gutter-y: $spacer * .5;
  }

  .gy-md-3 {
    --bs-gutter-y: $spacer;
  }

  .gy-md-4 {
    --bs-gutter-y: $spacer * 1.5;
  }

  .gy-md-5 {
    --bs-gutter-y: $spacer * 3;
  }

  .gy-md-auto {
    --bs-gutter-y: auto;
  }

  // Left and Right (X)
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .gx-md-1 {
    --bs-gutter-x: $spacer * .25;
  }

  .gx-md-2 {
    --bs-gutter-x: $spacer * .5;
  }

  .gx-md-3 {
    --bs-gutter-x: $spacer;
  }

  .gx-md-4 {
    --bs-gutter-x: $spacer * 1.5;
  }

  .gx-md-5 {
    --bs-gutter-x: $spacer * 3;
  }

  .gx-md-auto {
    --bs-gutter-x: auto;
  }
}




/**
 * Padding - LG
 */

@media (min-width: $screen-lg) {
  // All
  .g-lg-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }

  .g-lg-1 {
    --bs-gutter-x: $spacer * .25;
    --bs-gutter-y: $spacer * .25;
  }

  .g-lg-2 {
    --bs-gutter-x: $spacer * .5;
    --bs-gutter-y: $spacer * .5;
  }

  .g-lg-3 {
    --bs-gutter-x: $spacer;
    --bs-gutter-y: $spacer;
  }

  .g-lg-4 {
    --bs-gutter-x: $spacer * 1.5;
    --bs-gutter-y: $spacer * 1.5;
  }

  .g-lg-5 {
    --bs-gutter-x: $spacer * 3;
    --bs-gutter-y: $spacer * 3;
  }

  .g-lg-auto {
    --bs-gutter-x: auto;
    --bs-gutter-y: auto;
  }

  // Top and Bottom (Y)
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .gy-lg-1 {
    --bs-gutter-y: $spacer * .25;
  }

  .gy-lg-2 {
    --bs-gutter-y: $spacer * .5;
  }

  .gy-lg-3 {
    --bs-gutter-y: $spacer;
  }

  .gy-lg-4 {
    --bs-gutter-y: $spacer * 1.5;
  }

  .gy-lg-5 {
    --bs-gutter-y: $spacer * 3;
  }

  .gy-lg-auto {
    --bs-gutter-y: auto;
  }

  // Left and Right (X)
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .gx-lg-1 {
    --bs-gutter-x: $spacer * .25;
  }

  .gx-lg-2 {
    --bs-gutter-x: $spacer * .5;
  }

  .gx-lg-3 {
    --bs-gutter-x: $spacer;
  }

  .gx-lg-4 {
    --bs-gutter-x: $spacer * 1.5;
  }

  .gx-lg-5 {
    --bs-gutter-x: $spacer * 3;
  }

  .gx-lg-auto {
    --bs-gutter-x: auto;
  }
}