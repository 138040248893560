/**
 * Margins - All screens
 */

// All
.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: $spacer * .25 !important;
}

.m-2 {
  margin: $spacer * .5 !important;
}

.m-3 {
  margin: $spacer !important;
}

.m-4 {
  margin: $spacer * 1.5 !important;
}

.m-5 {
  margin: $spacer * 3 !important;
}

.m-auto {
  margin: auto !important;
}

//Top
.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin: $spacer * .25 !important;
}

.mt-2 {
  margin-top: $spacer * .5 !important;
}

.mt-3 {
  margin-top: $spacer !important;
}

.mt-4 {
  margin-top: $spacer * 1.5 !important;
}

.mt-5 {
  margin-top: $spacer * 3 !important;
}

.mt-auto {
  margin-top: auto !important;
}

//Bottom
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: $spacer * .25 !important;
}

.mb-2 {
  margin-bottom: $spacer * .5 !important;
}

.mb-3 {
  margin-bottom: $spacer !important;
}

.mb-4 {
  margin-bottom: $spacer * 1.5 !important;
}

.mb-5 {
  margin-bottom: $spacer * 3 !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

//Left (Start)
.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: $spacer * .25 !important;
}

.ms-2 {
  margin-left: $spacer * .5 !important;
}

.ms-3 {
  margin-left: $spacer !important;
}

.ms-4 {
  margin-left: $spacer * 1.5 !important;
}

.ms-5 {
  margin-left: $spacer * 3 !important;
}

.ms-auto {
  margin-bottom: auto !important;
}

//Right (End)
.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: $spacer * .25 !important;
}

.me-2 {
  margin-right: $spacer * .5 !important;
}

.me-3 {
  margin-right: $spacer !important;
}

.me-4 {
  margin-bottom: $spacer * 1.5 !important;
}

.me-5 {
  margin-right: $spacer * 3 !important;
}

.me-auto {
  margin-right: auto !important;
}

// Top and Bottom (Y)
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: $spacer * .25 !important;
  margin-bottom: $spacer * .25 !important;
}

.my-2 {
  margin-top: $spacer * .5 !important;
  margin-bottom: $spacer * .5 !important;
}

.my-3 {
  margin-top: $spacer !important;
  margin-bottom: $spacer !important;
}

.my-4 {
  margin-top: $spacer * 1.5 !important;
  margin-bottom: $spacer * 1.5 !important;
}

.my-5 {
  margin-top: $spacer * 3 !important;
  margin-bottom: $spacer * 3 !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

// Left and Right (X)
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: $spacer * .25 !important;
  margin-right: $spacer * .25 !important;
}

.mx-2 {
  margin-left: $spacer * .5 !important;
  margin-right: $spacer * .5 !important;
}

.mx-3 {
  margin-left: $spacer !important;
  margin-right: $spacer !important;
}

.mx-4 {
  margin-left: $spacer * 1.5 !important;
  margin-right: $spacer * 1.5 !important;
}

.mx-5 {
  margin-left: $spacer * 3 !important;
  margin-right: $spacer * 3 !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

/**
 * Margins - SM
 */

 @media (min-width: $screen-sm) {
  // All
  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: $spacer * .25 !important;
  }

  .m-sm-2 {
    margin: $spacer * .5 !important;
  }

  .m-sm-3 {
    margin: $spacer !important;
  }

  .m-sm-4 {
    margin: $spacer * 1.5 !important;
  }

  .m-sm-5 {
    margin: $spacer * 3 !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  //Top
  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin: $spacer * .25 !important;
  }

  .mt-sm-2 {
    margin-top: $spacer * .5 !important;
  }

  .mt-sm-3 {
    margin-top: $spacer !important;
  }

  .mt-sm-4 {
    margin-top: $spacer * 1.5 !important;
  }

  .mt-sm-5 {
    margin-top: $spacer * 3 !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  //Bottom
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: $spacer * .25 !important;
  }

  .mb-sm-2 {
    margin-bottom: $spacer * .5 !important;
  }

  .mb-sm-3 {
    margin-bottom: $spacer !important;
  }

  .mb-sm-4 {
    margin-bottom: $spacer * 1.5 !important;
  }

  .mb-sm-5 {
    margin-bottom: $spacer * 3 !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  //Left (Start)
  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: $spacer * .25 !important;
  }

  .ms-sm-2 {
    margin-left: $spacer * .5 !important;
  }

  .ms-sm-3 {
    margin-left: $spacer !important;
  }

  .ms-sm-4 {
    margin-left: $spacer * 1.5 !important;
  }

  .ms-sm-5 {
    margin-left: $spacer * 3 !important;
  }

  .ms-sm-auto {
    margin-bottom: auto !important;
  }

  //Right (End)
  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: $spacer * .25 !important;
  }

  .me-sm-2 {
    margin-right: $spacer * .5 !important;
  }

  .me-sm-3 {
    margin-right: $spacer !important;
  }

  .me-sm-4 {
    margin-bottom: $spacer * 1.5 !important;
  }

  .me-sm-5 {
    margin-right: $spacer * 3 !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  // Top and Bottom (Y)
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: $spacer * .25 !important;
    margin-bottom: $spacer * .25 !important;
  }

  .my-sm-2 {
    margin-top: $spacer * .5 !important;
    margin-bottom: $spacer * .5 !important;
  }

  .my-sm-3 {
    margin-top: $spacer !important;
    margin-bottom: $spacer !important;
  }

  .my-sm-4 {
    margin-top: $spacer * 1.5 !important;
    margin-bottom: $spacer * 1.5 !important;
  }

  .my-sm-5 {
    margin-top: $spacer * 3 !important;
    margin-bottom: $spacer * 3 !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  // Left and Right (X)
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: $spacer * .25 !important;
    margin-right: $spacer * .25 !important;
  }

  .mx-sm-2 {
    margin-left: $spacer * .5 !important;
    margin-right: $spacer * .5 !important;
  }

  .mx-sm-3 {
    margin-left: $spacer !important;
    margin-right: $spacer !important;
  }

  .mx-sm-4 {
    margin-left: $spacer * 1.5 !important;
    margin-right: $spacer * 1.5 !important;
  }

  .mx-sm-5 {
    margin-left: $spacer * 3 !important;
    margin-right: $spacer * 3 !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

}





/**
 * Margins - MD
 */

@media (min-width: $screen-md) {
  // All
  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: $spacer * .25 !important;
  }

  .m-md-2 {
    margin: $spacer * .5 !important;
  }

  .m-md-3 {
    margin: $spacer !important;
  }

  .m-md-4 {
    margin: $spacer * 1.5 !important;
  }

  .m-md-5 {
    margin: $spacer * 3 !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  //Top
  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin: $spacer * .25 !important;
  }

  .mt-md-2 {
    margin-top: $spacer * .5 !important;
  }

  .mt-md-3 {
    margin-top: $spacer !important;
  }

  .mt-md-4 {
    margin-top: $spacer * 1.5 !important;
  }

  .mt-md-5 {
    margin-top: $spacer * 3 !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  //Bottom
  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: $spacer * .25 !important;
  }

  .mb-md-2 {
    margin-bottom: $spacer * .5 !important;
  }

  .mb-md-3 {
    margin-bottom: $spacer !important;
  }

  .mb-md-4 {
    margin-bottom: $spacer * 1.5 !important;
  }

  .mb-md-5 {
    margin-bottom: $spacer * 3 !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  //Left (Start)
  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: $spacer * .25 !important;
  }

  .ms-md-2 {
    margin-left: $spacer * .5 !important;
  }

  .ms-md-3 {
    margin-left: $spacer !important;
  }

  .ms-md-4 {
    margin-left: $spacer * 1.5 !important;
  }

  .ms-md-5 {
    margin-left: $spacer * 3 !important;
  }

  .ms-md-auto {
    margin-bottom: auto !important;
  }

  //Right (End)
  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: $spacer * .25 !important;
  }

  .me-md-2 {
    margin-right: $spacer * .5 !important;
  }

  .me-md-3 {
    margin-right: $spacer !important;
  }

  .me-md-4 {
    margin-bottom: $spacer * 1.5 !important;
  }

  .me-md-5 {
    margin-right: $spacer * 3 !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  // Top and Bottom (Y)
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: $spacer * .25 !important;
    margin-bottom: $spacer * .25 !important;
  }

  .my-md-2 {
    margin-top: $spacer * .5 !important;
    margin-bottom: $spacer * .5 !important;
  }

  .my-md-3 {
    margin-top: $spacer !important;
    margin-bottom: $spacer !important;
  }

  .my-md-4 {
    margin-top: $spacer * 1.5 !important;
    margin-bottom: $spacer * 1.5 !important;
  }

  .my-md-5 {
    margin-top: $spacer * 3 !important;
    margin-bottom: $spacer * 3 !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  // Left and Right (X)
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: $spacer * .25 !important;
    margin-right: $spacer * .25 !important;
  }

  .mx-md-2 {
    margin-left: $spacer * .5 !important;
    margin-right: $spacer * .5 !important;
  }

  .mx-md-3 {
    margin-left: $spacer !important;
    margin-right: $spacer !important;
  }

  .mx-md-4 {
    margin-left: $spacer * 1.5 !important;
    margin-right: $spacer * 1.5 !important;
  }

  .mx-md-5 {
    margin-left: $spacer * 3 !important;
    margin-right: $spacer * 3 !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}




/**
 * Margins - LG
 */

@media (min-width: $screen-lg) {
  // All
  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: $spacer * .25 !important;
  }

  .m-lg-2 {
    margin: $spacer * .5 !important;
  }

  .m-lg-3 {
    margin: $spacer !important;
  }

  .m-lg-4 {
    margin: $spacer * 1.5 !important;
  }

  .m-lg-5 {
    margin: $spacer * 3 !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  //Top
  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin: $spacer * .25 !important;
  }

  .mt-lg-2 {
    margin-top: $spacer * .5 !important;
  }

  .mt-lg-3 {
    margin-top: $spacer !important;
  }

  .mt-lg-4 {
    margin-top: $spacer * 1.5 !important;
  }

  .mt-lg-5 {
    margin-top: $spacer * 3 !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  //Bottom
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: $spacer * .25 !important;
  }

  .mb-lg-2 {
    margin-bottom: $spacer * .5 !important;
  }

  .mb-lg-3 {
    margin-bottom: $spacer !important;
  }

  .mb-lg-4 {
    margin-bottom: $spacer * 1.5 !important;
  }

  .mb-lg-5 {
    margin-bottom: $spacer * 3 !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  //Left (Start)
  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: $spacer * .25 !important;
  }

  .ms-lg-2 {
    margin-left: $spacer * .5 !important;
  }

  .ms-lg-3 {
    margin-left: $spacer !important;
  }

  .ms-lg-4 {
    margin-left: $spacer * 1.5 !important;
  }

  .ms-lg-5 {
    margin-left: $spacer * 3 !important;
  }

  .ms-lg-auto {
    margin-bottom: auto !important;
  }

  //Right (End)
  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: $spacer * .25 !important;
  }

  .me-lg-2 {
    margin-right: $spacer * .5 !important;
  }

  .me-lg-3 {
    margin-right: $spacer !important;
  }

  .me-lg-4 {
    margin-bottom: $spacer * 1.5 !important;
  }

  .me-lg-5 {
    margin-right: $spacer * 3 !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  // Top and Bottom (Y)
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: $spacer * .25 !important;
    margin-bottom: $spacer * .25 !important;
  }

  .my-lg-2 {
    margin-top: $spacer * .5 !important;
    margin-bottom: $spacer * .5 !important;
  }

  .my-lg-3 {
    margin-top: $spacer !important;
    margin-bottom: $spacer !important;
  }

  .my-lg-4 {
    margin-top: $spacer * 1.5 !important;
    margin-bottom: $spacer * 1.5 !important;
  }

  .my-lg-5 {
    margin-top: $spacer * 3 !important;
    margin-bottom: $spacer * 3 !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  // Left and Right (X)
  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: $spacer * .25 !important;
    margin-right: $spacer * .25 !important;
  }

  .mx-lg-2 {
    margin-left: $spacer * .5 !important;
    margin-right: $spacer * .5 !important;
  }

  .mx-lg-3 {
    margin-left: $spacer !important;
    margin-right: $spacer !important;
  }

  .mx-lg-4 {
    margin-left: $spacer * 1.5 !important;
    margin-right: $spacer * 1.5 !important;
  }

  .mx-lg-5 {
    margin-left: $spacer * 3 !important;
    margin-right: $spacer * 3 !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}