// Typography
// -----------------------------------------------------------------------------

/**
 * Fonts
 */

/* squada-one-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Squada One';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/squada-one-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/squada-one-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/squada-one-v14-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('../fonts/squada-one-v14-latin-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
       url('../fonts/squada-one-v14-latin-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
       url('../fonts/squada-one-v14-latin-regular.svg#SquadaOne') format('svg'); /* Legacy iOS */
}

/* be-vietnam-pro-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/be-vietnam-pro-v10-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/be-vietnam-pro-v10-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/be-vietnam-pro-v10-latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('../fonts/be-vietnam-pro-v10-latin_latin-ext-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
       url('../fonts/be-vietnam-pro-v10-latin_latin-ext-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
       url('../fonts/be-vietnam-pro-v10-latin_latin-ext-regular.svg#BeVietnamPro') format('svg'); /* Legacy iOS */
}
/* be-vietnam-pro-700 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/be-vietnam-pro-v10-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
  src: url('../fonts/be-vietnam-pro-v10-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/be-vietnam-pro-v10-latin_latin-ext-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
       url('../fonts/be-vietnam-pro-v10-latin_latin-ext-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
       url('../fonts/be-vietnam-pro-v10-latin_latin-ext-700.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
       url('../fonts/be-vietnam-pro-v10-latin_latin-ext-700.svg#BeVietnamPro') format('svg'); /* Legacy iOS */
}

/* Setup */
html {
  /* Font Family */
  font-family: serif;

  /* Adjust font size */
  font-size: 100%;

  /* Font varient */
  font-variant-ligatures: none;

  /* Smoothing */
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0 0 0 / 1%) 0 0 1px;
}

ul,
ol,
p {
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  font-family: "Squada One", cursive;
  text-transform: uppercase;
  font-size: 3.052rem;
  font-weight: 700;
  line-height: 1;
}

h2 {
  font-family: "Squada One", cursive;
  text-transform: uppercase;
  font-size: 2.441rem;
  font-weight: 700;
  line-height: 1;
}

h3 {
  font-family: "Squada One", cursive;
  text-transform: uppercase;
  font-size: 1.953rem;
}

h4 {
  font-family: "Squada One", cursive;
  text-transform: uppercase;
  font-size: 1.563rem;
}

h5 {
  font-family: "Squada One", cursive;
  text-transform: uppercase;
  font-size: 1.25rem;
}

h6 {
  font-family: "Squada One", cursive;
  text-transform: uppercase;
  font-size: 1rem;
}

/* Tables */
table {
  margin-top: 4rem;
  border-spacing: 0;
  border-collapse: collapse;
}

table td,
table th {
  padding: 0;
  line-height: 33px;
}

/* Code blocks */
code {
  vertical-align: bottom;
}

/* Leading paragraph text */
.lead {
  font-family: "Squada One", cursive;
  text-transform: uppercase;
  font-size: 1.414rem;
  margin: 0 auto;
  line-height: 1.5rem;
}

/* Hug the block above you */
.hug {
  margin-top: 0;
}

.text-dark-green {
  color: #004a30;
}

.text-light-green {
  color: #96be0e;
}

.text-light {
  color: #d9d9d9 !important;
}

.text-white {
  color: #ffffff;
  a {
    color: #ffffff;
  }
  a:hover {
    text-decoration: none;
  }
}

.text-background-st-dark {
  background-color: rgba(0 0 0 / 50%);
  display: inline-block;
  padding: 15px;
}

.text-background-st-light {
  background-color: rgba(255 255 255 / 50%);
  display: inline-block;
  padding: 15px;
}

@media (max-width: $screen-sm) {
  .text-background-st-light-sm-max {
    background-color: rgba(255 255 255 / 50%);
    display: inline-block;
    padding: 15px;
  }

  .text-background-st-dark-sm-max {
    background-color: rgba(0 0 0 / 50%);
    display: inline-block;
    padding: 15px;
  }
}

@media (min-width: $screen-sm) {
  .text-background-st-light-sm-min {
    background-color: rgba(255 255 255 / 50%);
    display: inline-block;
    padding: 15px;
  }

  .text-background-st-dark-sm-min {
    background-color: rgba(0 0 0 / 50%);
    display: inline-block;
    padding: 15px;
  }
}

@media (max-width: $screen-lg) {
  .text-background-st-light-lg-max {
    background-color: rgba(255 255 255 / 50%);
    display: inline-block;
    padding: 15px;
  }

  .text-background-st-dark-lg-max {
    background-color: rgba(0 0 0 / 50%);
    display: inline-block;
    padding: 15px;
  }
}

@media (min-width: $screen-lg) {
  .text-background-st-light-lg-min {
    background-color: rgba(255 255 255 / 50%);
    display: inline-block;
    padding: 15px;
  }

  .text-background-st-dark-lg-min {
    background-color: rgba(0 0 0 / 50%);
    display: inline-block;
    padding: 15px;
  }
}

@media (max-width: $screen-md) {
  .text-background-st-light-md-max {
    background-color: rgba(255 255 255 / 50%);
    display: inline-block;
    padding: 15px;
  }

  .text-background-st-dark-md-max {
    background-color: rgba(0 0 0 / 50%);
    display: inline-block;
    padding: 15px;
  }
}

@media (min-width: $screen-md) {
  .text-background-st-light-md-min {
    background-color: rgba(255 255 255 / 50%);
    display: inline-block;
    padding: 15px;
  }

  .text-background-st-dark-md-min {
    background-color: rgba(0 0 0 / 50%);
    display: inline-block;
    padding: 15px;
  }
}

@media (max-width: $screen-xs) {
  .text-background-st-light-xs-max {
    background-color: rgba(255 255 255 / 50%);
    display: inline-block;
    padding: 15px;
  }

  .text-background-st-dark-xs-max {
    background-color: rgba(0 0 0 / 50%);
    display: inline-block;
    padding: 15px;
  }
}

@media (min-width: $screen-xs) {
  .text-background-st-light-xs-min {
    background-color: rgba(255 255 255 / 50%);
    display: inline-block;
    padding: 15px;
  }

  .text-background-st-dark-xs-min {
    background-color: rgba(0 0 0 / 50%);
    display: inline-block;
    padding: 15px;
  }
}

.page.serviss-page {
  h1.page-title {
    margin-bottom: 0;
  }
}

.explanation-title-no-number-single h3 {
  padding-top: 26px;
  padding-bottom: 26px;
}

.explanation-title-no-number-double h3{
  padding-top: 15px;
  padding-bottom: 15px;
}