// Styles for Menus and Navs
// -----------------------------------------------------------------------------


// Navbar Theming

.navbar {
  background-color: #000;
  border: none;
  color: #fff;
  margin-bottom: 0;
  font-family: 'Squada One', cursive;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1rem;

  .container {
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;

    @media (max-width: 1023px) {
      flex-direction: column;
    }
    @media (min-width: 1024px) {
      align-items: center;
    }
    .navbar-header {
      float: none;
      display: flex;
      padding: 2rem 0;
      margin: 0;

      @media (max-width: 360px) {
        flex-direction: column;
        align-items: center;;
      }

      .navbar-toggle {
        border: 0;
        background-color: #96be0e;
        float: none;
        margin: 9px 0;
        padding: 10px 20px;
      }

      .navbar-toggle span {
        background-color: #ffffff;
      }

      .navbar-toggle:focus {
        background-color: #96be0e;
      }

      .navbar-brand {
        float: none;
        height: auto;
        display: block;
        padding: 5px 0 35px 0;
        margin-left: 0;
        flex-grow: 1;

        #logo {
          float: none;;
        }
      }
    }
  }
  #navbar {
    width: 100%;
    border:0;
    height: 0;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    padding: 0;

    @media (max-width: $screen-sm) {
      flex-direction: column;
    }

    @media (min-width: 1024px) {
      display: flex !important;
      #main-menu.navbar-nav {
        margin-left: 0 auto !important;
        justify-content: center !important;
      }
    }

    #main-menu.navbar-nav {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      justify-content: center;
      margin: 7px 0;
      @media (min-width: 768px) {
        margin-left: -24px;
        justify-content: flex-start;
      }

      li {
        width: 100px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          width: 100%;
        }
        a {
          color: #D9D9D9;
        }
      }
    }
    .navbar-right {
      float: none !important;
      display: flex;
      flex-wrap: wrap;
      align-items: middle;
      margin: 0;
      padding-bottom: 30px;
      @media (max-width: 255px) {
        flex-direction: column;
      }
      @media (min-width: 769px) {
        padding-bottom: 0;
      }
      .dropdown .dropdown-menu {
        height: 0;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;
      }
      .dropdown.open {
        .dd-pointer-down {
          border-color: rgba(0,0,0,0) rgba(0,0,0,0) #ffffff !important;
          border-image: none !important;
          border-style: solid !important;
          border-width: 5px !important;
          margin-top: -8px;
        }
        .dropdown-menu {
          position: absolute;
          float: none;
          width: auto;
          height: 129px;
          margin-top: 0;
          background-color: #f6f6f6;
          border: 0;
          box-shadow: none;
          min-width: auto;
          transition: height 250ms;
          li>a {
            padding: 10px 24px;
            text-align: center;
            border-bottom: 1px solid #ddd;
          }
        }
      }
      .account-nav-button {
        color: #f8f9fa;
        background-color: #96be0e;
        //margin-right: 20px;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-right: 30px;
        padding-left: 26px;
        min-width: 150px;
        text-align: right;
        .fa {
          padding-right: 12px;
        }
        .dd-pointer-down {
          border-top: solid 5px white;
        }
      }
    }
  }
  .navbar-collapse[aria-expanded ="false"] {
    display: none;
  }
  .navbar-collapse[aria-expanded ="true"] {
    display: flex;
    height: calc(100vh) !important;
    @media (min-width: 769px) {
      height: 78px !important;
    }
    @media (max-width: 768px) {
      min-height: 340px;
    }
    overflow: visible !important;
  }
  .navbar-collapse:after {
    content: none;
  }

}



/*
.navbar-nav {
  width: 100%;
  text-align: center;
  > li {
    float: none;
    display: inline-block;
  }
}

.navbar-nav {
  height: 133px;
}

.navbar-nav::before {
  content:"";
  display: inline-block;
  vertical-align: middle;
  height: 100%;;
}

.navbar-nav>li {
  vertical-align: middle;
}*/