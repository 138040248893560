// Page Layout
// -----------------------------------------------------------------------------

.breadcrumb {
  margin-bottom: 0;
}

// Node Type: Serviss Page
.node-type-serviss-page #main #content {
  margin: 0;
  padding: 0;
}