/**
 * Padding - All screens
 */

// All
.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: $spacer * .25 !important;
}

.p-2 {
  padding: $spacer * .5 !important;
}

.p-3 {
  padding: $spacer !important;
}

.p-4 {
  padding: $spacer * 1.5 !important;
}

.p-5 {
  padding: $spacer * 3 !important;
}

.p-auto {
  padding: auto !important;
}

//Top
.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding: $spacer * .25 !important;
}

.pt-2 {
  padding-top: $spacer * .5 !important;
}

.pt-3 {
  padding-top: $spacer !important;
}

.pt-4 {
  padding-top: $spacer * 1.5 !important;
}

.pt-5 {
  padding-top: $spacer * 3 !important;
}

.pt-auto {
  padding-top: auto !important;
}

//Bottom
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: $spacer * .25 !important;
}

.pb-2 {
  padding-bottom: $spacer * .5 !important;
}

.pb-3 {
  padding-bottom: $spacer !important;
}

.pb-4 {
  padding-bottom: $spacer * 1.5 !important;
}

.pb-5 {
  padding-bottom: $spacer * 3 !important;
}

.pb-auto {
  padding-bottom: auto !important;
}

//Left (Start)
.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: $spacer * .25 !important;
}

.ps-2 {
  padding-left: $spacer * .5 !important;
}

.ps-3 {
  padding-left: $spacer !important;
}

.ps-4 {
  padding-left: $spacer * 1.5 !important;
}

.ps-5 {
  padding-left: $spacer * 3 !important;
}

.ps-auto {
  padding-bottom: auto !important;
}

//Right (End)
.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: $spacer * .25 !important;
}

.pe-2 {
  padding-right: $spacer * .5 !important;
}

.pe-3 {
  padding-right: $spacer !important;
}

.pe-4 {
  padding-bottom: $spacer * 1.5 !important;
}

.pe-5 {
  padding-right: $spacer * 3 !important;
}

.pe-auto {
  padding-right: auto !important;
}

// Top and Bottom (Y)
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: $spacer * .25 !important;
  padding-bottom: $spacer * .25 !important;
}

.py-2 {
  padding-top: $spacer * .5 !important;
  padding-bottom: $spacer * .5 !important;
}

.py-3 {
  padding-top: $spacer !important;
  padding-bottom: $spacer !important;
}

.py-4 {
  padding-top: $spacer * 1.5 !important;
  padding-bottom: $spacer * 1.5 !important;
}

.py-5 {
  padding-top: $spacer * 3 !important;
  padding-bottom: $spacer * 3 !important;
}

.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}

// Left and Right (X)
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: $spacer * .25 !important;
  padding-right: $spacer * .25 !important;
}

.px-2 {
  padding-left: $spacer * .5 !important;
  padding-right: $spacer * .5 !important;
}

.px-3 {
  padding-left: $spacer !important;
  padding-right: $spacer !important;
}

.px-4 {
  padding-left: $spacer * 1.5 !important;
  padding-right: $spacer * 1.5 !important;
}

.px-5 {
  padding-left: $spacer * 3 !important;
  padding-right: $spacer * 3 !important;
}

.px-auto {
  padding-left: auto !important;
  padding-right: auto !important;
}

/**
 * Padding - SM
 */

 @media (min-width: $screen-sm) {
  // All
  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: $spacer * .25 !important;
  }

  .p-sm-2 {
    padding: $spacer * .5 !important;
  }

  .p-sm-3 {
    padding: $spacer !important;
  }

  .p-sm-4 {
    padding: $spacer * 1.5 !important;
  }

  .p-sm-5 {
    padding: $spacer * 3 !important;
  }

  .p-sm-auto {
    padding: auto !important;
  }

  //Top
  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding: $spacer * .25 !important;
  }

  .pt-sm-2 {
    padding-top: $spacer * .5 !important;
  }

  .pt-sm-3 {
    padding-top: $spacer !important;
  }

  .pt-sm-4 {
    padding-top: $spacer * 1.5 !important;
  }

  .pt-sm-5 {
    padding-top: $spacer * 3 !important;
  }

  .pt-sm-auto {
    padding-top: auto !important;
  }

  //Bottom
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: $spacer * .25 !important;
  }

  .pb-sm-2 {
    padding-bottom: $spacer * .5 !important;
  }

  .pb-sm-3 {
    padding-bottom: $spacer !important;
  }

  .pb-sm-4 {
    padding-bottom: $spacer * 1.5 !important;
  }

  .pb-sm-5 {
    padding-bottom: $spacer * 3 !important;
  }

  .pb-sm-auto {
    padding-bottom: auto !important;
  }

  //Left (Start)
  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: $spacer * .25 !important;
  }

  .ps-sm-2 {
    padding-left: $spacer * .5 !important;
  }

  .ps-sm-3 {
    padding-left: $spacer !important;
  }

  .ps-sm-4 {
    padding-left: $spacer * 1.5 !important;
  }

  .ps-sm-5 {
    padding-left: $spacer * 3 !important;
  }

  .ps-sm-auto {
    padding-bottom: auto !important;
  }

  //Right (End)
  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: $spacer * .25 !important;
  }

  .pe-sm-2 {
    padding-right: $spacer * .5 !important;
  }

  .pe-sm-3 {
    padding-right: $spacer !important;
  }

  .pe-sm-4 {
    padding-bottom: $spacer * 1.5 !important;
  }

  .pe-sm-5 {
    padding-right: $spacer * 3 !important;
  }

  .pe-sm-auto {
    padding-right: auto !important;
  }

  // Top and Bottom (Y)
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: $spacer * .25 !important;
    padding-bottom: $spacer * .25 !important;
  }

  .py-sm-2 {
    padding-top: $spacer * .5 !important;
    padding-bottom: $spacer * .5 !important;
  }

  .py-sm-3 {
    padding-top: $spacer !important;
    padding-bottom: $spacer !important;
  }

  .py-sm-4 {
    padding-top: $spacer * 1.5 !important;
    padding-bottom: $spacer * 1.5 !important;
  }

  .py-sm-5 {
    padding-top: $spacer * 3 !important;
    padding-bottom: $spacer * 3 !important;
  }

  .py-sm-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  // Left and Right (X)
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: $spacer * .25 !important;
    padding-right: $spacer * .25 !important;
  }

  .px-sm-2 {
    padding-left: $spacer * .5 !important;
    padding-right: $spacer * .5 !important;
  }

  .px-sm-3 {
    padding-left: $spacer !important;
    padding-right: $spacer !important;
  }

  .px-sm-4 {
    padding-left: $spacer * 1.5 !important;
    padding-right: $spacer * 1.5 !important;
  }

  .px-sm-5 {
    padding-left: $spacer * 3 !important;
    padding-right: $spacer * 3 !important;
  }

  .px-sm-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }

}





/**
 * Padding - MD
 */

@media (min-width: $screen-md) {
  // All
  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: $spacer * .25 !important;
  }

  .p-md-2 {
    padding: $spacer * .5 !important;
  }

  .p-md-3 {
    padding: $spacer !important;
  }

  .p-md-4 {
    padding: $spacer * 1.5 !important;
  }

  .p-md-5 {
    padding: $spacer * 3 !important;
  }

  .p-md-auto {
    padding: auto !important;
  }

  //Top
  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding: $spacer * .25 !important;
  }

  .pt-md-2 {
    padding-top: $spacer * .5 !important;
  }

  .pt-md-3 {
    padding-top: $spacer !important;
  }

  .pt-md-4 {
    padding-top: $spacer * 1.5 !important;
  }

  .pt-md-5 {
    padding-top: $spacer * 3 !important;
  }

  .pt-md-auto {
    padding-top: auto !important;
  }

  //Bottom
  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: $spacer * .25 !important;
  }

  .pb-md-2 {
    padding-bottom: $spacer * .5 !important;
  }

  .pb-md-3 {
    padding-bottom: $spacer !important;
  }

  .pb-md-4 {
    padding-bottom: $spacer * 1.5 !important;
  }

  .pb-md-5 {
    padding-bottom: $spacer * 3 !important;
  }

  .pb-md-auto {
    padding-bottom: auto !important;
  }

  //Left (Start)
  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: $spacer * .25 !important;
  }

  .ps-md-2 {
    padding-left: $spacer * .5 !important;
  }

  .ps-md-3 {
    padding-left: $spacer !important;
  }

  .ps-md-4 {
    padding-left: $spacer * 1.5 !important;
  }

  .ps-md-5 {
    padding-left: $spacer * 3 !important;
  }

  .ps-md-auto {
    padding-bottom: auto !important;
  }

  //Right (End)
  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: $spacer * .25 !important;
  }

  .pe-md-2 {
    padding-right: $spacer * .5 !important;
  }

  .pe-md-3 {
    padding-right: $spacer !important;
  }

  .pe-md-4 {
    padding-bottom: $spacer * 1.5 !important;
  }

  .pe-md-5 {
    padding-right: $spacer * 3 !important;
  }

  .pe-md-auto {
    padding-right: auto !important;
  }

  // Top and Bottom (Y)
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: $spacer * .25 !important;
    padding-bottom: $spacer * .25 !important;
  }

  .py-md-2 {
    padding-top: $spacer * .5 !important;
    padding-bottom: $spacer * .5 !important;
  }

  .py-md-3 {
    padding-top: $spacer !important;
    padding-bottom: $spacer !important;
  }

  .py-md-4 {
    padding-top: $spacer * 1.5 !important;
    padding-bottom: $spacer * 1.5 !important;
  }

  .py-md-5 {
    padding-top: $spacer * 3 !important;
    padding-bottom: $spacer * 3 !important;
  }

  .py-md-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  // Left and Right (X)
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: $spacer * .25 !important;
    padding-right: $spacer * .25 !important;
  }

  .px-md-2 {
    padding-left: $spacer * .5 !important;
    padding-right: $spacer * .5 !important;
  }

  .px-md-3 {
    padding-left: $spacer !important;
    padding-right: $spacer !important;
  }

  .px-md-4 {
    padding-left: $spacer * 1.5 !important;
    padding-right: $spacer * 1.5 !important;
  }

  .px-md-5 {
    padding-left: $spacer * 3 !important;
    padding-right: $spacer * 3 !important;
  }

  .px-md-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
}




/**
 * Padding - LG
 */

@media (min-width: $screen-lg) {
  // All
  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: $spacer * .25 !important;
  }

  .p-lg-2 {
    padding: $spacer * .5 !important;
  }

  .p-lg-3 {
    padding: $spacer !important;
  }

  .p-lg-4 {
    padding: $spacer * 1.5 !important;
  }

  .p-lg-5 {
    padding: $spacer * 3 !important;
  }

  .p-lg-auto {
    padding: auto !important;
  }

  //Top
  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding: $spacer * .25 !important;
  }

  .pt-lg-2 {
    padding-top: $spacer * .5 !important;
  }

  .pt-lg-3 {
    padding-top: $spacer !important;
  }

  .pt-lg-4 {
    padding-top: $spacer * 1.5 !important;
  }

  .pt-lg-5 {
    padding-top: $spacer * 3 !important;
  }

  .pt-lg-auto {
    padding-top: auto !important;
  }

  //Bottom
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: $spacer * .25 !important;
  }

  .pb-lg-2 {
    padding-bottom: $spacer * .5 !important;
  }

  .pb-lg-3 {
    padding-bottom: $spacer !important;
  }

  .pb-lg-4 {
    padding-bottom: $spacer * 1.5 !important;
  }

  .pb-lg-5 {
    padding-bottom: $spacer * 3 !important;
  }

  .pb-lg-auto {
    padding-bottom: auto !important;
  }

  //Left (Start)
  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: $spacer * .25 !important;
  }

  .ps-lg-2 {
    padding-left: $spacer * .5 !important;
  }

  .ps-lg-3 {
    padding-left: $spacer !important;
  }

  .ps-lg-4 {
    padding-left: $spacer * 1.5 !important;
  }

  .ps-lg-5 {
    padding-left: $spacer * 3 !important;
  }

  .ps-lg-auto {
    padding-bottom: auto !important;
  }

  //Right (End)
  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: $spacer * .25 !important;
  }

  .pe-lg-2 {
    padding-right: $spacer * .5 !important;
  }

  .pe-lg-3 {
    padding-right: $spacer !important;
  }

  .pe-lg-4 {
    padding-bottom: $spacer * 1.5 !important;
  }

  .pe-lg-5 {
    padding-right: $spacer * 3 !important;
  }

  .pe-lg-auto {
    padding-right: auto !important;
  }

  // Top and Bottom (Y)
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: $spacer * .25 !important;
    padding-bottom: $spacer * .25 !important;
  }

  .py-lg-2 {
    padding-top: $spacer * .5 !important;
    padding-bottom: $spacer * .5 !important;
  }

  .py-lg-3 {
    padding-top: $spacer !important;
    padding-bottom: $spacer !important;
  }

  .py-lg-4 {
    padding-top: $spacer * 1.5 !important;
    padding-bottom: $spacer * 1.5 !important;
  }

  .py-lg-5 {
    padding-top: $spacer * 3 !important;
    padding-bottom: $spacer * 3 !important;
  }

  .py-lg-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }

  // Left and Right (X)
  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: $spacer * .25 !important;
    padding-right: $spacer * .25 !important;
  }

  .px-lg-2 {
    padding-left: $spacer * .5 !important;
    padding-right: $spacer * .5 !important;
  }

  .px-lg-3 {
    padding-left: $spacer !important;
    padding-right: $spacer !important;
  }

  .px-lg-4 {
    padding-left: $spacer * 1.5 !important;
    padding-right: $spacer * 1.5 !important;
  }

  .px-lg-5 {
    padding-left: $spacer * 3 !important;
    padding-right: $spacer * 3 !important;
  }

  .px-lg-auto {
    padding-left: auto !important;
    padding-right: auto !important;
  }
}