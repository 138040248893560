// Bootstrap 5 specific definitions
// --------------------------------------------------

/**
* Text
*/

// Text alignment
.text-start {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-end {
  text-align: right !important;
}

@media (min-width: $screen-xs) {
  .text-xs-start {
    text-align: left !important;
  }

  .text-xs-center {
    text-align: center !important;
  }

  .text-xs-end {
    text-align: right !important;
  }
}

@media (min-width: $screen-sm) {
  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .text-sm-end {
    text-align: right !important;
  }
}

@media (min-width: $screen-md) {
  .text-md-start {
    text-align: left !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-end {
    text-align: right !important;
  }
}

@media (min-width: $screen-lg) {
  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .text-lg-end {
    text-align: right !important;
  }
}

// Text wrapping and overflow
.text-wrap {
  white-space: normal!important;
}

.text-nowrap {
  white-space: nowrap!important;
}

// Word break
.text-break {
  word-wrap: break-word!important;
  word-break: break-word!important;
}

// Text transform
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

@media (min-width: $screen-md) {
  .text-md-start {
    text-align: left !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-end {
    text-align: right !important;
  }
}

@media (min-width: $screen-lg) {
  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .text-lg-end {
    text-align: right !important;
  }
}

// Font size
.fs-1 {
  font-size: 2.5rem !important;
}

.fs-2 {
  font-size: 2rem !important;
}

.fs-3 {
  font-size: 1.75rem !important;
}

.fs-4 {
  font-size: 1.5rem !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

@media (min-width: $screen-sm) {
  .fs-sm-1 {
    font-size: 2.5rem !important;
  }

  .fs-sm-2 {
    font-size: 2rem !important;
  }

  .fs-sm-3 {
    font-size: 1.75rem !important;
  }

  .fs-sm-4 {
    font-size: 1.5rem !important;
  }

  .fs-sm-5 {
    font-size: 1.25rem !important;
  }

  .fs-sm-6 {
    font-size: 1rem !important;
  }
}

@media (min-width: $screen-md) {
  .fs-md-1 {
    font-size: 2.5rem !important;
  }

  .fs-md-2 {
    font-size: 2rem !important;
  }

  .fs-md-3 {
    font-size: 1.75rem !important;
  }

  .fs-md-4 {
    font-size: 1.5rem !important;
  }

  .fs-md-5 {
    font-size: 1.25rem !important;
  }

  .fs-md-6 {
    font-size: 1rem !important;
  }
}

@media (min-width: $screen-lg) {
  .fs-lg-1 {
    font-size: 2.5rem !important;
  }

  .fs-lg-2 {
    font-size: 2rem !important;
  }

  .fs-lg-3 {
    font-size: 1.75rem !important;
  }

  .fs-lg-4 {
    font-size: 1.5rem !important;
  }

  .fs-lg-5 {
    font-size: 1.25rem !important;
  }

  .fs-lg-6 {
    font-size: 1rem !important;
  }
}

// Font weight and italics
.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}